import { useHistory } from 'react-router';

import type { Equipment } from 'shared/models/swagger';

import { RuleListItem } from './RuleListItem';
import { NoSchedule } from './NoSchedule';

interface Props {
  equipment: Equipment;
}

export const RuleList = ({ equipment }: Props) => {
  const history = useHistory();

  if ((equipment.equipment_schedules || []).length === 0) {
    return <NoSchedule />;
  }

  return (
    <div>
      {(equipment?.equipment_schedules || []).map((schedule, index) => (
        <RuleListItem
          schedule={schedule}
          key={index}
          onEditClick={() => {
            history.push(
              `/seats/resourceschedules/${equipment.id}#schedule=${schedule.key}`
            );
          }}
        />
      ))}
    </div>
  );
};
