import { ProductAdder } from './ProductAdder';
import { ProductListItem } from './ProductListItem';

export const ProductList = () => {
  return (
    <div>
      <h1>Product List</h1>
      {/* Add your product list content here */}
      <ProductAdder />
      <ProductListItem />
    </div>
  );
};
