import { combineReducers } from 'redux';
import type { Action } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import {
  CREATE_INTERNAL_CHAT_FAILURE,
  CREATE_INTERNAL_CHAT_REQUEST,
  CREATE_INTERNAL_CHAT_SUCCESS,
  FETCH_INTERNAL_CHATS_FAILURE,
  FETCH_INTERNAL_CHATS_REQUEST,
  FETCH_INTERNAL_CHATS_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  CreateInternalChatResponse,
  InternalChat,
  ListInternalChatsResponse,
} from 'shared/models/swagger';

// For now keep this function here and move if it's needed as util
function buildCookieString(
  key: string,
  value: string,
  expirationInDays?: number
): string {
  let cookieString = `${key}=${value};`;

  if (expirationInDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationInDays);
    cookieString += `expires=${expirationDate.toUTCString()};`;
  }

  return cookieString + 'path=/';
}

const conversationIdKey = 'conversation-id';

const getCompoundKey = (supplierId: string, userId: string): string =>
  `${conversationIdKey}-${supplierId}-user-${userId}`;

export const loadConversationId = (
  supplierId: string,
  userId: string
): string => {
  // No supplier or user, do not create conversation id
  if (!supplierId || !userId) {
    return '';
  }

  const compoundKey = getCompoundKey(supplierId, userId);

  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${compoundKey}=`));
  const cookieConversationId = cookie?.split('=')[1];
  if (cookieConversationId) {
    return cookieConversationId;
  }

  const conversationId = uuidv4();
  document.cookie = buildCookieString(compoundKey, conversationId, 90);

  return conversationId;
};

export const clearConversationId = (
  supplierId: string,
  userId: string
): void => {
  // No supplier or user, do not proceed
  if (!supplierId || !userId) {
    return;
  }
  const compoundKey = getCompoundKey(supplierId, userId);
  document.cookie = buildCookieString(compoundKey, '', -1);
};

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_INTERNAL_CHATS_FAILURE:
    case CREATE_INTERNAL_CHAT_FAILURE:
      return action.error;

    case FETCH_INTERNAL_CHATS_SUCCESS:
    case CREATE_INTERNAL_CHAT_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_INTERNAL_CHATS_REQUEST:
    case CREATE_INTERNAL_CHAT_REQUEST:
      return true;

    case FETCH_INTERNAL_CHATS_FAILURE:
    case CREATE_INTERNAL_CHAT_FAILURE:
    case FETCH_INTERNAL_CHATS_SUCCESS:
    case CREATE_INTERNAL_CHAT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: InternalChat[] = [], action: any): InternalChat[] => {
  switch (action.type) {
    case FETCH_INTERNAL_CHATS_SUCCESS: {
      const response = action.response as ListInternalChatsResponse;
      return response.internal_chats;
    }

    case CREATE_INTERNAL_CHAT_SUCCESS: {
      const response = action.response as CreateInternalChatResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    default:
      return state;
  }
};

export const internalChats = combineReducers({
  error,
  loading,
  all,
});
