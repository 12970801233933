import { RecurrenceTable } from './RecurrenceTable';

export const ProductListItem = () => {
  return (
    <div>
      <h2>Product Name</h2>
      {/* Add your product list item content here */}
      <RecurrenceTable />
    </div>
  );
};
