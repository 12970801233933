import { ParticipationRuleController } from './ParticipationRuleController';
import { ParticipationRule } from './ParticipationRule';

export const EquipmentScheduleEditor = () => {
  return (
    <div>
      <h1>Equipment Schedule Editor</h1>
      {/* Add your resource list content here */}
      <ParticipationRuleController />
      <ParticipationRule />
    </div>
  );
};
