import React, { useState, useRef, useEffect } from 'react';

import sendIcon from '../../images/chat/mail-send.svg';

import styles from './InternalChat.module.css';
import { ChatInputProps } from './types';

export const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  const handleSubmit = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
      // Reset height after sending
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Skip if IME composition is in progress
    if (e.nativeEvent.isComposing || e.keyCode === 229) {
      return;
    }

    if (e.key === 'Enter') {
      if (e.shiftKey || e.metaKey || e.ctrlKey) {
        // Insert newline at cursor position
        const start = e.currentTarget.selectionStart;
        const end = e.currentTarget.selectionEnd;
        const newValue =
          message.substring(0, start) + '\n' + message.substring(end);
        setMessage(newValue);

        // Prevent default and maintain cursor position
        e.preventDefault();
        setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.selectionStart =
              textareaRef.current.selectionEnd = start + 1;
          }
        }, 0);
      } else {
        // Just Enter key alone - submit the message
        e.preventDefault();
        handleSubmit();
      }
    }
  };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor="messageInput" className={styles.visuallyHidden}>
        Type a message
      </label>
      <textarea
        ref={textareaRef}
        id="messageInput"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        className={styles.messageInput}
        placeholder="Message"
        aria-label="Type a message"
        rows={1}
      />
      <button
        className={styles.sendButton}
        aria-label="Send message"
        onClick={handleSubmit}
      >
        <img src={sendIcon} />
      </button>
    </div>
  );
};
