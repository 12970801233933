import { RangeEditor } from './RangeEditor';
import { StartTimeAdder } from './StartTimeAdder';
import { StartTimeSelector } from './StartTimeSelector';
import { ProductList } from './ProductList';

export const ParticipationRule = () => {
  return (
    <div>
      <h1>Participation Rule Editor</h1>
      <RangeEditor />
      <StartTimeAdder />
      <StartTimeSelector />
      <ProductList />
    </div>
  );
};
